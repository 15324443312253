import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import woaRo from "../../images/rollovers/graphic-design/woa-ro.png"
import "../layout.css"

import img1 from '../../images/graphic-design/world-of-anarchie/1.jpg'
import img2 from '../../images/graphic-design/world-of-anarchie/2.jpg'
import img3 from '../../images/graphic-design/world-of-anarchie/3.jpg'
import img4 from '../../images/graphic-design/world-of-anarchie/4.jpg'
import img5 from '../../images/graphic-design/world-of-anarchie/5.jpg'
import img6 from '../../images/graphic-design/world-of-anarchie/6.jpg'
import img7 from '../../images/graphic-design/world-of-anarchie/7.jpg'
import img8 from '../../images/graphic-design/world-of-anarchie/8.jpg'
import img9 from '../../images/graphic-design/world-of-anarchie/9.jpg'
import img10 from '../../images/graphic-design/world-of-anarchie/10.jpg'
import img11 from '../../images/graphic-design/world-of-anarchie/11.jpg'
import img12 from '../../images/graphic-design/world-of-anarchie/12.jpg'
import img13 from '../../images/graphic-design/world-of-anarchie/13.jpg'
import img14 from '../../images/graphic-design/world-of-anarchie/14.jpg'
import img15 from '../../images/graphic-design/world-of-anarchie/15.jpg'
import img16 from '../../images/graphic-design/world-of-anarchie/16.jpg'

export default function WorldOfAnarchie() {
    let images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16]
    return (
        <GalleryBackground title="World of Anarchie" imgSrc={woaRo}>
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}